@import '../jspm_packages/npm/bootstrap@4.3.0/scss/bootstrap';
@import '../jspm_packages/npm/humane-js@3.2.2/themes/flatty.css';
@import '../jspm_packages/npm/animate.css@3.7.0/animate.min.css';
@import '../jspm_packages/npm/jsoneditor@7.1.0/dist/jsoneditor.min.css';

@import "variables";

html, body, router-view {
    background-color: $dark;
    height: 100%;
}

label, .modal-dialog, .table {
    color: $font-color !important;
}

.navbar, .card-footer {
    background-color: $darker !important;
}

.navbar, .navbar-brand, .card, .card-footer {
    color: $font-color !important;
}

.modal-content, .card {
    background-color: $dark !important;
}

.close {
    color: $font-color !important;
}

.white {
    color: #ffffff !important;
}

.btn.disabled, .btn:disabled {
    opacity: 0.25 !important;
}

.card-body:hover {
    background-color: $darker;
    border-color: $darker;
}

.splash {
  text-align: center;
  margin: 10% 0 0 0;
  box-sizing: border-box;
}

.splash .message {
  font-size: 72px;
  line-height: 72px;
  text-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;
  text-transform: uppercase;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.splash .fa-spinner {
  text-align: center;
  display: inline-block;
  font-size: 72px;
  margin-top: 50px;
}

.page-host {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    z-index: 1050 !important;
}
.modal-lg {
    min-width: 800px;
}

.modal-xxl {
    min-width: 1400px;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.button-like {
    cursor: pointer;
}

.ignore-margin {
    margin: 0px;
}

.vertical-center {
    height: 100vh;
    display: flex;
    align-items: center;
}

.create-dashboard-icon:hover { color: #28a745 !important; }
.edit-dashboard-icon:hover { color: #0079ff !important; }
.rename-dashboard-icon:hover { color: #ffc107 !important; }
.code-dashboard-icon:hover { color: #17a2b8 !important; }
.delete-dashboard-icon { color: #dc3545 !important; }


.dashboard-id { color: #c4c4c4; font-size: 14px; }
.dashboard-public-icon { color: #0079ff; }
.dashboard-example { background-color: $dashboard-edit; overflow-y: auto; }

.params-editors { height: 350px; }

.humane.humane-libnotify-info,
.humane-libnotify.humane-libnotify-info {
  color: #ffffff;
  background: #17a2b8;
  background-color: #17a2b8;
}
.humane.humane-libnotify-success,
.humane-libnotify.humane-libnotify-success {
  color: #ffffff;
  background: #28a745;
  background-color: #28a745;
}
.humane.humane-libnotify-error,
.humane-libnotify.humane-libnotify-error {
  color: #ffffff;
  background: #dc3545;
  background-color: #dc3545;
}
